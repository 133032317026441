import React from 'react'
import { Helmet } from 'react-helmet'

const SEO = ({ title, image }) => {
  const description =
    'We are an early stage seed investment firm dedicated to investing in digital native fashion, beauty and lifestyle brands in order to define the future of e-commerce.'
  return (
    <>
      <Helmet>
        <html lang='en' />
        <title>{title}</title>
        <meta name='description' content={description} />
        {image && <meta name='image' content={`https:${image}`} />}
        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
        {image && <meta property='og:image' content={`https:${image}`} />}
      </Helmet>
    </>
  )
}

export default SEO
